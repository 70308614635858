import React from 'react';

class SearchBar extends React.Component {
    state = { videoSearchTerm: '' };

    onInputChange = (e) => {
        this.setState({ videoSearchTerm: e.target.value });
    };

    onFormSubmit = (e) => {
        e.preventDefault();

        this.props.onVideoSearchTermSubmit(this.state.videoSearchTerm);
    };

    render() {
        return (
            <div className="search-bar ui segment">
                <form onSubmit={this.onFormSubmit} className="ui form">
                    <div className="field">
                        <label>Video Search</label>
                        <input
                            type='text'
                            value={this.state.videoSearchTerm}
                            onChange={this.onInputChange}
                        />
                    </div>
                </form>
            </div>
        );
    }
}

export default SearchBar;