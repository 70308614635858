import axios from 'axios';

const KEY = 'AIzaSyBYioQQm3fKnk9oZPP_MnLl1y0-ndmtMOU';

export default axios.create({
    baseURL: 'https://www.googleapis.com/youtube/v3',
    params: {
        part: 'snippet',
        type: 'video',
        channelId: 'UCeeZr9GjW3lLIetYNcb7X8g',
        maxResults: 25,
        key: KEY
    }
});